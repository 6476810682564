<button class="btn btn-primary reloadButton " (click)="reloadComponent($event)"><i class="fa fa-refresh"></i>
  Reload</button>
<button *ngIf="clearResultButton" class="btn btn-secondary reloadButton " (click)="clearComponent($event)"><i
    class="fa fa-times"></i>
  Clear Search</button>

<div class="mat-elevation-z8 table-container table-responsive matTable ">
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let column of sqColumnDefinition" matColumnDef="{{column.name}}">
      <ng-container *ngIf="column.name ==='select';then checkbox else nocheckbox"></ng-container>
      <ng-template #checkbox>
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
        </mat-cell>
      </ng-template>
      <ng-template #nocheckbox>
        <mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column.disableSorting"
          (click)="sortTable(column)">
          <div class="column-header-content">
            <span> {{column.displayName}}</span>
            <mat-icon *ngIf="column.icon" class="icon-align">{{column.icon}}</mat-icon>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]=" column.class??''">
          <div class="mobileHeader">
            <span class="mobile-title">{{column.displayName}} </span>
            <span>
              {{(column.name === 'type') ? (element[column.name] === 2) ? '$' + element[column.value] :
              element[column.value] + '%' : element[column.name]}}
            </span>

            <!--  to show payment button in first column -->
            <div *ngIf="column.name ==='payment' && (userLevel && (userLevel.level != 2))">
              <button mat-menu-item (click)="getActions(element, 'payment')" title="Payment"
                [ngClass]="{'disabled': (element.event_expired && element.event_expired == '1')}" class="p-0"
                *ngIf="(element.school_type && element.approval_status === 'Approved' && element.registration_status === 'Active' && element.school_payment_sts !== 'Verify Payment' && element.school_payment_sts !== 'Completed') || element.payment_sts === 'Pending'  || element.payment_sts === '-'  || element.eventPayment_sts === 'Pending' || element.funeral_payment_sts === 'Pending' || element.myEvent_payment_sts === 'Pending' || (element.student_fees_status==='Pending' && element.school_payment_sts === 'Completed' && element.approval_status === 'Approved') || element.myCourse_payment_sts === 'Pending' || element.course_Registered_Course_payment === 'Pending' || element.viewcourse_Registered_Course_payment === 'Pending' || element.donation_Payment_sts === 'Pending'; else showNone">
                <mat-icon class="payment-color" *ngIf="!element.event_expired">payment</mat-icon>
                <span>{{element.event_expired ? 'None': 'Pay'}}</span>
              </button>
              <ng-template #showNone>
                <button mat-menu-item class="p-0 hide-cursor">
                  <span>None</span>
                </button>
              </ng-template>
            </div>

            <div *ngIf="column.name=='slugy'"> <a (click)="getActions(element, 'link')" mat-menu-item>Click here
              </a>
            </div>

            <div *ngIf="column.name ==='actions'">
              <!-- {{element | json}} -->
              <button mat-menu-item [matMenuTriggerFor]="menu" *ngIf="( element.actual_course_fee !== '$0.00')">
                <mat-icon class="task">task</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="getActions(element, 'view')" title="View"
                  *ngIf="permission.view_permission && ( element.eventPayment_sts !== 'Pending' && element.eventPayment_sts !== 'Completed' && element.eventPayment_sts !== 'Verify Payment')">
                  <mat-icon class="blue-color">visibility</mat-icon><span
                    class="menu-item-font-size">{{element.myEvent_payment_sts ? 'View Event':
                    'View'}}</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'Participants')" title="Participants"
                  *ngIf="permission.view_permission && element.view_participant == 1 ">
                  <mat-icon class="blue-color">visibility</mat-icon><span
                    class="menu-item-font-size">Participants</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'copy')" title="Copy Registration Data"
                  *ngIf="!isNullOrUndefined(element.school_copy_registartion) && isLevelIncluded(userLevel.level)">
                  <mat-icon class="blue-color mr-0">autorenew</mat-icon>
                  <span class="menu-item-font-size"> Renew (For New Academic Year)</span>
                </button>

                <button mat-menu-item (click)="getActions(element, 'view-participants')" title="View"
                  *ngIf="permission.view_permission && element.myEvent_payment_sts">
                  <mat-icon class="blue-color">visibility</mat-icon><span class="menu-item-font-size">View
                    Participants</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'checkin')" title="View"
                  *ngIf="element.is_checked == 0">
                  <mat-icon style="color:green;">check_circle</mat-icon><span class="menu-item-font-size">Check
                    In</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'edit')" title="Edit"
                  *ngIf="permission.edit_permission  && ( !element.donation_Payment_sts && element.payment_sts !== 'Completed' && element.eventPayment_sts !== 'Pending' && element.eventPayment_sts !== 'Completed' && element.eventPayment_sts !== 'Verify Payment') || (element.funeral_payment_sts === 'Completed' && (userLevel && (userLevel.level != 2))) ">
                  <mat-icon class="blue-color">edit</mat-icon><span class="menu-item-font-size">Edit</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'edit')" title="Edit"
                  *ngIf="permission.edit_permission  && (element.donation_Payment_sts && (userLevel && (userLevel.level == 1 || userLevel.level == 4))) ">
                  <mat-icon class="blue-color">edit</mat-icon><span class="menu-item-font-size">Edit</span>
                </button>

                <button mat-menu-item (click)="getActions(element, 'approve')" title="Approve"
                  *ngIf="(element.approve_permission && (element.approval_status === 'Pending' || element.approval_status === 'Rejected')) ">
                  <mat-icon class="blue-color">approval</mat-icon><span class="menu-item-font-size">Approve</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'reject')" title="Reject"
                  *ngIf="(element.approve_permission  && element.approval_status === 'Pending')">
                  <mat-icon class="blue-color">swipe_left</mat-icon><span class="menu-item-font-size">Reject</span>
                </button>

                <button mat-menu-item (click)="getActions(element, 'Verify Payment')" title="Verify Payment"
                  *ngIf="(element.payment_sts === 'Verify Payment' || element.school_payment_sts === 'Verify Payment' || element.eventPayment_sts === 'Verify Payment' || element.funeral_payment_sts === 'Verify Payment' || element.myCourse_payment_sts === 'Verify Payment' || element.viewcourse_Registered_Course_payment === 'Verify Payment' || element.myCourse_payment_sts === 'Verify Payment' || element.donation_Payment_sts === 'Verify Payment' ||element.student_fees_status==='Verify Payment')  && (userLevel && (userLevel.level == 1 || userLevel.level == 4) )">
                  <mat-icon class="blue-color">payment</mat-icon><span class="menu-item-font-size">Verify Payment</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'downloadPDF')"
                  [title]="element.donation_Payment_sts ? 'Receipt Download' : 'Invoice Download'" *ngIf="(userLevel && (userLevel.level != 2)) && (element.payment_sts === 'Completed'  || element.eventPayment_sts === 'Completed' || element.funeral_payment_sts === 'Completed' || element.myEvent_payment_sts === 'Completed' || element.viewcourse_Registered_Course_payment === 'Completed'|| element.myCourse_payment_sts === 'Completed' || element.donation_Payment_sts === 'Completed' ) && 
                  (element.hasOwnProperty('receipt_file') ? element.receipt_file !== null : true)
                  ||element.downloadPdf==true">
                  <mat-icon class="blue-color">download</mat-icon><span class="menu-item-font-size">Download</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'active')" title="Active"
                  *ngIf="element.registration_status === 'In-active' && (userLevel && (userLevel.level == 1 || userLevel.level == 4))">
                  <mat-icon class="active-color">done</mat-icon><span class="menu-item-font-size">Active</span>
                </button>

                <button mat-menu-item (click)="getActions(element, 'delete')" title="Delete" *ngIf="(permission.delete_permission ||  element.deleteForOnlySuperadmin_Truserer)  && ((element.donation_Payment_sts ||  element.eventPayment_sts || element.viewcourse_Registered_Course_payment || isNullOrUndefined (element.payment_sts ) ||
                ( !element.donation_Payment_sts && element.payment_sts !== 'Completed' && element.eventPayment_sts !== 'Pending' && element.eventPayment_sts !== 'Completed' && element.eventPayment_sts !== 'Verify Payment') 
                
                )  &&  (userLevel && (userLevel.level == 1 || userLevel.level == 4 ))) ">
                  <mat-icon class="red-color">delete</mat-icon><span class="menu-item-font-size">Delete</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'Unsubscribe')" title="Stop Recurring"
                  *ngIf="(permission.delete_permission ||  element.deleteForOnlySuperadmin_Truserer || element.user_id == element.created_by || element.user_id == element.entry_by)  && ((element.donation_Payment_sts ||  element.eventPayment_sts || element.viewcourse_Registered_Course_payment || element.myCourse_payment_sts || element.student_fees_status) &&  (userLevel && (userLevel.level == 1 || userLevel.level == 4 || element.created_by==userLevel.uid))) && ((! isNullOrUndefined( element.recurring_type) && element.recurring_status == 1) || element.student_fees_status==='Active' )">
                  <mat-icon class="cancel-color">clear</mat-icon><span class="menu-item-font-size">Stop Recurring</span>
                </button>
                <button mat-menu-item (click)="getActions(element, 'receipts')" title="Receipts"
                  *ngIf="(permission.delete_permission ||  element.deleteForOnlySuperadmin_Truserer || element.user_id == element.created_by || element.user_id == element.entry_by)  && ((element.donation_Payment_sts ||  element.eventPayment_sts || element.viewcourse_Registered_Course_payment || element.myCourse_payment_sts || element.student_fees_status) &&  (userLevel && (userLevel.level == 1 || userLevel.level == 4 || element.created_by==userLevel.uid))) && ((! isNullOrUndefined( element.recurring_type)) || element.student_fees_status==='Active'||element.student_fees_status==='Closed' )">
                  <mat-icon class="mr-0">credit_card</mat-icon><span class="menu-item-font-size">Recurring
                    Receipts</span>
                </button>

              </mat-menu>
            </div>
          </div>
        </mat-cell>
      </ng-template>
      <ng-container *ngIf="column.name ==='actions'">
        <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
      </ng-container>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnNames; sticky: true"></mat-header-row>
    <ng-container *ngIf="rowData && !loader">
      <mat-row [ngClass]="{'green-row': row.is_checked === 1}" *matRowDef="let row; columns: columnNames;"
        (click)="rowSelect(row)">
      </mat-row>
    </ng-container>
  </mat-table>
  <ng-container *ngIf="rowData.length <= 0 && !loader">
    <div *ngIf="rowData.length <= 0" class="text-center d-flex-inline">
      <mat-card appearance="outlined" class="no-data">
        <img class="no-data-image mt-2 mb-1 " src="../../../../assets/logo/no_data.svg">
        <div class="no-record mb-2">No data available in this table. You can start by creating a new entry.</div>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngIf="!rowData || loader">
    <div class="text-center loader" *ngIf="loader || !rowData">
      <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate" color="accent">
      </mat-progress-spinner>
    </div>
  </ng-container>
  <div class="loading-overlay" *ngIf="reload">
    <mat-spinner class="spinner"></mat-spinner>
    <div class="loading-text">Reloading...</div>
  </div>
  <ng-container *ngIf="sqPaginationConfig && sqPaginationConfig.enablePagination && rowData.length > 0">
    <mat-paginator class="page" [pageSize]="sqPaginationConfig.pageSize"
      [pageSizeOptions]="sqPaginationConfig.pageSizeOptions"
      [showFirstLastButtons]="sqPaginationConfig.showFirstLastButtons" (page)="getData($event)" [length]="length">
    </mat-paginator>
  </ng-container>
</div>