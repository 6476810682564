import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {
  ColumnSettingsModel,
  TablePaginationSettingsModel,
} from '../constants/table-settings.model';
import { BaseComponent } from '../abstract/base.component';
import { AppConstants } from '../constants/app-constants';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { Router } from '@angular/router';
import { timeout } from 'rxjs-compat/operator/timeout';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.css'],
})
export class GenericTableComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges
{
  selectedRowIndex = -1;
  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  columnNames: string[] = [];
  @Input() enableCheckbox: boolean;
  @Input() clearResultButton: boolean;
  @Output() clearSearchEvent = new EventEmitter<void>();
  @Input() allowMultiSelect: boolean;
  @Input() sqColumnDefinition: ColumnSettingsModel[];
  @Input() sqPaginationConfig: TablePaginationSettingsModel;
  @Input() rowData: object[];
  selection = new SelectionModel<{}>();
  dataSource: MatTableDataSource<{}>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { read: false }) paginator: MatPaginator;
  @Output() getSelectedRows = new EventEmitter();
  @Output() getPagination = new EventEmitter();
  @Output() getActionTable = new EventEmitter();
  @Input() length: number;
  @Output() getSorting = new EventEmitter();
  @Input() permission: any;
  @Input() loader: boolean;
  userLevel: any;
  reload: boolean = false;
  constructor(
    service: DataManagerService,
    private router: Router,
  ) {
    super();
    this.service = service;
    this.getUserLevel();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    console.log('this.paginator', this.paginator);
    this.dataSource.paginator = this.paginator;
    //console.log('this.dataSource', this.dataSource);
  }
  ngOnChanges() {
    console.log('this.rowData', this.rowData);
    this.dataSource = new MatTableDataSource(this.rowData);
    this.dataSource.sort = this.sort;
    console.log('this.paginator', this.paginator);
    this.dataSource.paginator = this.paginator;
  }

  // isAllSelected() {
  //     const numSelected = this.selection.selected.length;
  //     const numRows = this.dataSource.data.length;
  //     return numSelected === numRows;
  // }
  // masterToggle() {
  //     this.isAllSelected() ?
  //         this.selection.clear() :
  //         this.dataSource.data.forEach((row: any) => this.selection.select(row));
  //     this.getSelectedRows.emit(this.selection.selected);

  // }
  rowSelect(row: any) {
    this.getSelectedRows.emit(row);
  }

  ngOnInit() {
    for (const column of this.sqColumnDefinition) {
      this.columnNames.push(column.name);
    }
    // if (this.enableCheckbox) {
    //     this.columnNames.splice(0, 0, 'select');
    //     this.sqColumnDefinition.splice(0, 0, {
    //         'name': 'select',
    //         'displayName': '#'
    //     });
    // }
    this.selection = new SelectionModel<{}>(this.allowMultiSelect, []);
    this.dataSource = new MatTableDataSource(this.rowData);
  }
  // highlight(row: any) {
  //     this.selectedRowIndex = row.position;
  // }

  getData(event: any) {
    this.getPagination.emit(event);
  }
  getActions(data: any, actions: string) {
    let tableData = {
      data: data,
      actions: actions,
    };
    this.getActionTable.emit(tableData);
  }
  sortTable(matSort: any) {
    console.log(this.sort.getNextSortDirection(matSort));
    this.getSorting.emit(this.sort);
  }
  getUserLevel() {
    const levelUrl = AppConstants.BASE_URL + '/groups/' + this.loged_user.gid;
    this.service.getById(levelUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.userLevel = res.data.row;
          console.log('this.userLevel', this.userLevel);
          this.formLoader = false;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
          this.formLoader = false;
        }
      },
      error: (error) => {
        this.service.showSnackBar(error.message, 'ok', 3000);
        this.formLoader = false;
      },
    });
  }
  reloadComponent(event: any) {
    this.reload = true;

    this.getData(event);
    setTimeout(() => {
      this.reload = false;
    }, 2000);
  }
  clearComponent(event: Event) {
    event.stopPropagation();
    this.clearSearchEvent.emit();
  }
  isLevelIncluded(level: number): boolean {
    return [1, 4, 3, 7].includes(level);
  }
}
