<div class="row title justify-content">

    <mat-card-title class="mat-card-title text-lg-center text-xl-center"> {{ headings }}</mat-card-title>

</div>
<div class="content">
    <div class="row justify-content-space-between">
        <div class="col-lg-4 col-xl-4 col-md-8 col-sm-12 col-12 form-group has-search">
            <span class="fa fa-search form-control-feedback"></span>
            <input type="search" class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2"
                [(ngModel)]="searchText" (input)="searchHits.next(true)" placeholder="Search" />
        </div>
        <div class="col-lg-8 col-xl-8 col-md-8 col-sm-12 col-12 text-right">
            <button class="btn btn-primary mr-2" [routerLink]="['/fundraisers/home']">View Active Fundraisers</button>
            <button *ngIf="create_permission" class="btn btn-primary mr-2" (click)="createNew()">Create a
                Fundraiser</button>

        </div>
    </div>
    <div>
        <div appearance="outlined" class="list" *ngIf="list_permission">
            <app-generic-table [rowData]="rowData" [enableCheckbox]="true" [allowMultiSelect]="true" [loader]="loader"
                [permission]="permissionsCheck" [sqPaginationConfig]="tablePaginationSettings"
                (getPagination)="getPageData($event)" (getActionTable)="getActions($event)" (getSorting)="sort($event)"
                [sqColumnDefinition]="columnDefinition" [length]="resultsLength">
            </app-generic-table>
        </div>
    </div>
</div>