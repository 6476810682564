<mat-card appearance="outlined" class="view-page mat-card mat-focus-indicator p-0 mx-3">
    <div class="form-field-full">
        <div class="card">

            <div class="card-body">
                <div class="toolbar-nav">
                    <div class="col-12 col-lg-7 col-xl-7 col-md-7 p-0 col-sm-6">
                        <h1 class="title">View Event Participants</h1>
                    </div>
                    <div class=" text-right mt-2 mb-2">
                        <button class="btn btn-primary mr-3 text-right" (click)="exportexcel()"
                            *ngIf="showExport">Export <i *ngIf="loadFlag2"
                                class="fa fa-spinner fa-pulse loader mt-1 ml-1"></i></button>
                        <button class="btn btn-primary" [routerLink]="['/all-events/events']" name="back"><i
                                class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</button>
                    </div>
                    <div class="row stats-container">
                        <div class="col-md-4 col-sm-12">
                            <mat-card appearance="outlined" class="stat-card small-card">
                                <mat-card-title class="stat-title">Total Participants</mat-card-title>
                                <mat-card-content>
                                    <h5 class="stat-number">{{ totalNumberOfParticipants }}</h5>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <mat-card appearance="outlined" class="stat-card small-card checked-in">
                                <mat-card-title class="stat-title">Checked-in</mat-card-title>
                                <mat-card-content>
                                    <h5 class="stat-number">{{ totalNumberOfCheckInParticipants }}</h5>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <mat-card appearance="outlined" class="stat-card small-card not-checked-in">
                                <mat-card-title class="stat-title">Not Checked-in</mat-card-title>
                                <mat-card-content>
                                    <h5 class="stat-number">{{ totalNumberOfNotCheckInParticipants }}</h5>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <div class="row">


                        <div class="modal-container" *ngIf="isModalOpen">
                            <div class="modal-content">
                                <!-- Your HTML content goes here -->
                                <button class="closeButton  mb-2 mt-2" (click)="closeModal()"><i
                                        class="fa fa-close mr-2" aria-hidden="true"></i></button>
                                <div class="scanner-shell" [hidden]="!hasDevices">

                                    <mat-menu #appMenu="matMenu">
                                        <mat-selection-list>
                                            <mat-list-option (click)="toggleTryHarder()" [selected]="tryHarder">
                                                Enable Try-harder
                                            </mat-list-option>
                                            <mat-list-option (click)="toggleTorch()" [selected]="torchEnabled"
                                                *ngIf="torchAvailable$ | async">
                                                Enable Torch
                                            </mat-list-option>
                                        </mat-selection-list>
                                        <mat-divider></mat-divider>
                                    </mat-menu>

                                    <header>
                                        <mat-form-field appearance="fill">
                                            <select matNativeControl matInput
                                                (change)="onDeviceSelectChange($event.target.value)">
                                                <option value="" [selected]="!currentDevice">No Device Selected</option>
                                                <option *ngFor="let device of availableDevices"
                                                    [value]="device.deviceId"
                                                    [selected]="currentDevice && device.deviceId === currentDevice.deviceId">
                                                    {{device.label}}</option>
                                            </select>
                                        </mat-form-field>
                                        <button mat-icon-button [matMenuTriggerFor]="appMenu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                    </header>

                                    <zxing-scanner [torch]="torchEnabled" [(device)]="currentDevice"
                                        (scanSuccess)="onCodeResult($event)" [formats]="formatsEnabled"
                                        [tryHarder]="true" (permissionResponse)="onHasPermission($event)"
                                        (camerasFound)="onCamerasFound($event)"
                                        (torchCompatible)="onTorchCompatible($event)"></zxing-scanner>



                                </div>

                                <ng-container *ngIf="hasPermission === undefined">

                                    <h2>Waiting for permissions.</h2>

                                    <blockquote>
                                        If your device does not has cameras, no permissions will be asked.
                                    </blockquote>

                                </ng-container>

                                <ng-container *ngIf="hasPermission === false">

                                    <h2>You denied the camera permission, we can't scan anything without it. 😪</h2>

                                </ng-container>

                                <ng-container *ngIf="hasDevices === undefined">

                                    <h2>Couldn't check for devices.</h2>

                                    <blockquote>
                                        This may be caused by some security error.
                                    </blockquote>

                                </ng-container>

                                <ng-container *ngIf="hasDevices === false">

                                    <h2>No devices were found.</h2>

                                    <blockquote>
                                        I believe your device has no media devices attached to.
                                    </blockquote>

                                </ng-container>
                            </div>
                        </div>

                        <div class="row justify-content-space-between">
                            <div class="col-lg-4 col-xl-4 col-md-8 col-sm-12 col-12 form-group has-search">
                                <span class="fa fa-search form-control-feedback"></span>
                                <input type="search"
                                    class="form-control search-bar col-xl-12 col-lg-12 col-md-12 col-12 py-2"
                                    [(ngModel)]="searchText" (input)="searchHits.next(true)" placeholder="Search" />
                                <button class="btn btn-secondary mt-2" (click)="openModal()">Open QR Scanner
                                    Modal</button>
                                <section class="results" *ngIf="qrResultString && resultsLength >0">
                                    <div>
                                        <small>Result</small>
                                        <strong>{{ qrResultString }}</strong>
                                        <button mat-icon-button (click)="clearResult()">&times;</button>

                                    </div>
                                    <strong style="color:green">{{ successSerialMessage }}</strong>

                                </section>
                                <section class="results" *ngIf="qrResultString && resultsLength == 0">
                                    <div>
                                        <small>Result</small>
                                        <strong>{{ qrResultString }}</strong>
                                        <button mat-icon-button (click)="clearResult()">&times;</button>
                                    </div>
                                    <div>
                                        <strong style="color:red"> {{ notFoundSerial }}</strong>
                                    </div>

                                </section>
                            </div>

                        </div>


                        <mat-tab-group (selectedTabChange)="onTabChange($event)" class="">

                            <ng-template #showLoader>
                                <div class="text-center loader">
                                    <mat-progress-spinner diameter=50 style="margin: 0 auto" mode="indeterminate"
                                        color="accent">
                                    </mat-progress-spinner>
                                </div>
                            </ng-template>

                            <mat-tab label="Event Participants">

                                <mat-card appearance="outlined" class="list p-0">
                                    <app-generic-table [rowData]="rowData" [enableCheckbox]="true"
                                        [clearResultButton]="searchText != ''  && searchText != null"
                                        [allowMultiSelect]="true" [permission]="permissionsCheck"
                                        [sqPaginationConfig]="tablePaginationSettings"
                                        (getPagination)="getPageData($event)" (getActionTable)="getActions($event)"
                                        (getSorting)="sort($event)" [sqColumnDefinition]="columnDefinition"
                                        (clearSearchEvent)="clearResult()" [length]="resultsLength"></app-generic-table>
                                </mat-card>
                            </mat-tab>

                        </mat-tab-group>
                    </div>
                </div>

            </div>
        </div>
    </div>
</mat-card>