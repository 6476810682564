import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataManagerService } from 'src/app/services/DataManager/data-manager.service';
import { BaseComponent } from 'src/app/shared/abstract/base.component';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
@Component({
  selector: 'app-add-donation',
  templateUrl: './add-donation.component.html',
  styleUrls: ['./add-donation.component.css'],
})
export class AddDonationComponent extends BaseComponent implements OnInit {
  public donationForm: FormGroup;
  public payWord: string = 'Donate';

  public loged_user = JSON.parse(localStorage.getItem('user') || 'null');
  public donationUrl: string;
  userLevelList: any;
  showtoAdmin: boolean = false;
  public edit_Id: Boolean = false;
  public recurringOption: String = '';
  public formMessage: string;

  public maxDate: any;
  public endDate: any;
  public minDate: any;
  public amount: number;
  showDateError: boolean;
  isLoading: boolean = false;
  token: any;
  updateFlag: boolean = false;
  manual: boolean = false;
  receiptId: number;
  updateUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    dataManagerService: DataManagerService,
    private datePipe: DatePipe,
  ) {
    super();
    this.service = dataManagerService;
    this.countryListUrl = AppConstants.BASE_URL + '/country';
    this.maritalStatusUrl = AppConstants.BASE_URL + '/maritalStatus';
    this.stateListUrl = AppConstants.BASE_URL + '/getState';
    this.cityListUrl = AppConstants.BASE_URL + '/getCity';
    this.donationUrl = AppConstants.BASE_URL + AppConstants.ADDDONATIONS_URL;
    if (
      this.matchUrl('donations/manualreceipts/create') ||
      this.matchUrlWithId('donations/manualreceipts/:id/edit', this.router.url)
    ) {
      this.donationUrl = AppConstants.BASE_URL + '/manual-receipts';
      this.manual = true;
    }

    const currentYear = new Date().getFullYear();

    this.minDate = new Date(
      currentYear - 0,
      new Date().getMonth(),
      new Date().getDate(),
    );
    this.endDate = new Date(
      currentYear - 0,
      new Date().getMonth(),
      new Date().getDate(),
    );
    this.maxDate = new Date(currentYear + 10, 11, 31);
    this.getDonationType();
  }
  ngOnInit(): void {
    this.getCountryList();
    this.setdonationForm();
  }
  get donationFormControl() {
    return this.donationForm.controls;
  }
  setdonationForm() {
    this.donationForm = this.formBuilder.group(
      {
        donation_amount: [
          this.data && this.data.donation_amount
            ? this.data.donation_amount
            : '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.min(0.01),
            Validators.max(9999999.99),
          ],
        ],
        donation_type: [
          this.data && this.data.donation_type ? this.data.donation_type : '',
          [Validators.required],
        ],
        fname: [
          this.data && this.data.fname ? this.data.fname : '',
          [Validators.required],
        ],
        lname: [
          this.data && this.data.lname ? this.data.lname : '',
          [Validators.required],
        ],
        email: [
          this.data && this.data.email ? this.data.email : '',
          [Validators.required],
        ],
        contact_number: [
          this.data && this.data.contact_number ? this.data.contact_number : '',
          [
            Validators.required,
            Validators.pattern(/((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/),
          ],
        ],
        country: [
          this.data && this.data.country ? this.data.country : '',
          [Validators.required],
        ],
        state: [
          this.data && this.data.state ? this.data.state : '',
          Validators.required,
        ],
        city: [
          this.data && this.data.city ? this.data.city : '',
          [Validators.required],
        ],
        payment_method: [
          this.data && this.data.payment_method
            ? String(this.data.payment_method)
            : '',
          [Validators.required],
        ],
        cheque_number: [
          this.data && this.data.cheque_number ? this.data.cheque_number : '',
        ],
        donation_date: [
          this.data && this.data.donation_date
            ? this.datePipe.transform(this.data.donation_date, 'yyyy-MM-dd')
            : '',
        ],
        donation_to_date: [
          this.data && this.data.donation_to_date
            ? this.datePipe.transform(this.data.donation_to_date, 'yyyy-MM-dd')
            : '',
        ],
        recurring_count: [
          this.data && this.data.recurring_count
            ? this.data.recurring_count
            : '',
        ],
        recurring_date: [
          this.data && this.data.recurring_date
            ? this.data.recurring_date
            : this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
        ],
        payment_frequency: [
          this.data && this.data.recurring_type
            ? this.data.recurring_type.toString()
            : '',
        ],
      },
      { validators: this.dateRangeValidator },
    );
  }
  // Custom Validator
  dateRangeValidator(control: AbstractControl) {
    const fromDate = control.get('donation_date')?.value;
    const toDate = control.get('donation_to_date')?.value;

    if (toDate && !fromDate) {
      return { fromDateRequired: true };
    }

    if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
      return { invalidDateRange: true }; // fromDate must be before toDate
    }

    return null;
  }
  onSubmit(token: any) {
    this.token = token;
    this.submitted = true;
    console.log('this.donationForm', this.donationForm);
    if (this.donationForm.invalid) {
      this.formMessage = 'Please Fill Required Fields';

      return;
    } else {
      this.isLoading = true;

      this.loadFlag2 = true;
      if (this.data) {
        this.donationForm.value.id = this.data.id;
      }
      if (this.receiptId) {
        this.updateUrl =
          this.donationUrl + '/' + this.receiptId + '?_method=PUT';
      }

      this.donationForm.value.contact_number =
        this.donationForm.value.contact_number.replace(/\D+/g, '');
      this.donationForm.value.payment_token = this.token;

      this.donationForm.value.donation_date = this.datePipe.transform(
        this.donationForm.value.donation_date,
        'yyyy-MM-dd',
      );
      this.donationForm.value.donation_to_date = this.datePipe.transform(
        this.donationForm.value.donation_to_date,
        'yyyy-MM-dd',
      );
      this.service
        .create(
          this.receiptId ? this.updateUrl : this.donationUrl,
          this.donationForm.value,
        )
        .subscribe({
          next: (res: any) => {
            if (this.manual) {
              this.router.navigate(['donations/manualreceipts']);
            }
            if (res['status'] == 'success' && res.data) {
              this.loadFlag2 = false;
              this.router.navigate(['../checkout'], {
                queryParams: { id: res.data.payable_id },
                relativeTo: this.route,
              });
            } else if (
              res['status'] == 'success' &&
              !res.data &&
              !res['approve']
            ) {
              this.loadFlag2 = false;
              this.router.navigate(['/donations/alldonation']);
              this.service.showSnackBar(res.message, 'ok', 3000);
            } else if (
              res &&
              res['status'] &&
              res['status'] == 'success' &&
              res['approve'] == 1
            ) {
              this.service.showSnackBar(res.response.messageText, 'ok', 3000);
              this.router.navigate(['../../payment'], {
                queryParams: {
                  id: res.payment.id,
                },
                relativeTo: this.route,
                skipLocationChange: true,
              });
            } else if (res && res['status'] && res['status'] == 'Denied') {
              this.service.showSnackBar(
                'Transaction Denied Please Try Again',
                'ok',
                3000,
              );
              this.service.showSnackBar(res.response.messageText, 'ok', 3000);

              this.router.navigate(['../../payment'], {
                queryParams: {
                  id: res.payment.id,
                },
                relativeTo: this.route,
                skipLocationChange: true,
              });
              setTimeout(() => {
                // After the operation is complete, hide the spinner
                this.isLoading = false;
              }, 2000);
            } else if (res && res['status'] && res['status'] == 'error') {
              this.service.showSnackBar(res.message, 'ok', 3000);
              setTimeout(() => {
                // After the operation is complete, hide the spinner
                this.isLoading = false;
              }, 2000);
              this.router.navigate(['/login']);
            } else {
              this.loadFlag2 = false;
              this.topErrorMessage = res.message;
              this.keys = Object.keys(this.topErrorMessage);
              this.keys1 = Object.values(this.topErrorMessage);
              let i;
              let j;
              const name = [
                'donation_amount',
                'donation_type',
                'fname',
                'lname',
                'email',
                'contact_number',
                'country',
                'state',
                'city',
                'payment_method',
                'cheque_number',
              ];
              for (i = 0; i < this.keys.length; i++) {
                for (j = 0; j < name.length; j++) {
                  if (this.keys[i].match(name[j])) {
                    const documents = document.getElementById(name[j]);
                    if (documents) {
                      documents.style.borderColor = '#a94442';
                      documents.style.display = 'block';
                    }
                  }
                }
              }
            }
          },
          error: (error) => {
            this.loadFlag2 = false;

            this.service.showSnackBar(error.message, 'ok', 3000);
            setTimeout(() => {
              // After the operation is complete, hide the spinner
              this.isLoading = false;
            }, 2000);
          },
        });
    }
  }
  cancel() {
    if (
      this.matchUrl('/donations/add-donation') ||
      this.matchUrl('/donations/edit-donation')
    ) {
      this.router.navigate(['/donations/alldonation']);
    } else {
      this.router.navigate(['dontations/manualreceipts']);
    }
  }
  getFormData() {
    this.route.paramMap.subscribe((params) => {
      this.receiptId = Number(params.get('id')); // Convert string to number
    });
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      if (params['id'] || this.receiptId) {
        this.edit_Id = true;
        const editId = params['id'] ?? this.receiptId;
        console.log(this.edit_Id, this.loged_user.gid, this.loged_user.uid);
        this.service.getById(this.donationUrl + '/' + editId).subscribe({
          next: (res: any) => {
            if (res['status'] == 'success') {
              if (res.data) {
                this.updateFlag = true;
                this.data = res.data.row;
                console.log(this.data);

                if (this.data.contact_number) {
                  const phone = this.data.contact_number.match(
                    /(\d{3})(\d{3})(\d{4})/,
                  );
                  this.data.contact_number =
                    '(' + phone[1] + ') ' + phone[2] + '-' + phone[3];
                }
                this.selectdCountry = this.data.country;
                this.changeCountry();
                this.selectdState = this.data.state;
                this.changeState();
                this.setRecurring(
                  this.getRecurringType(this.data.recurring_type),
                );
                this.selectdCity = this.data.city;
              }
              this.setdonationForm();
              this.donationForm.controls['cheque_number'].clearValidators();
              this.donationForm.controls[
                'cheque_number'
              ].updateValueAndValidity();

              this.headings = 'Update Donation';
              this.buttonText = 'Update';
            } else {
              this.service.showSnackBar(res.message, 'ok', 3000);
            }
          },
          error: (error) => {
            this.service.showSnackBar(error.message, 'ok', 3000);
          },
        });
      } else {
        this.headings = 'New Donation';
        this.buttonText = 'Submit';
      }
    });
  }
  getDonationType() {
    this.formLoader = true;
    const getListUrl = AppConstants.BASE_URL + '/donation_type';
    this.service.getAllList(getListUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.dontationTypeList = res.data;
          if (this.dontationTypeList) {
            this.getUserLevel();
            this.getFormData();
          }
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
          this.formLoader = false;
        }
      },
      error: (error) => {
        this.service.showSnackBar(error.message, 'ok', 3000);
        this.formLoader = false;
      },
    });
  }
  getUserLevel() {
    const levelUrl = AppConstants.BASE_URL + '/groups/' + this.loged_user.gid;
    this.service.getById(levelUrl).subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.userLevelList = res.data.row;

          if (
            this.userLevelList.level == 1 ||
            this.userLevelList.level == 2 ||
            this.userLevelList.level == 4
          ) {
            this.showtoAdmin = true;
          } else {
            this.showtoAdmin = false;
          }
          console.log(this.userLevelList, this.paymentMethodList);
          this.formLoader = false;
        } else {
          this.service.showSnackBar(res.message, 'ok', 3000);
          this.formLoader = false;
        }
      },
      error: (error) => {
        this.formLoader = false;
        this.service.showSnackBar(error.message, 'ok', 3000);
      },
    });
  }
  enableChequeNumber(event: any) {
    if (event === 'Cheque') {
      this.buttonText = 'Submit';
      this.donationForm.controls['cheque_number'].setValidators([
        Validators.required,
      ]);
      this.donationForm.controls['cheque_number'].updateValueAndValidity();
    } else {
      if (event === 'Online') {
        this.buttonText = 'Proceed To Checkout';
      } else {
        this.buttonText = 'Submit';
      }
      this.donationForm.controls['cheque_number'].clearValidators();
      this.donationForm.controls['cheque_number'].updateValueAndValidity();
    }
  }

  setFrequencyValidation() {
    if (this.donationForm.value.payment_frequency != 1) {
      this.donationForm.controls['recurring_date'].setValidators([
        Validators.required,
      ]);
      this.donationForm.controls['recurring_date'].updateValueAndValidity();
      this.donationForm.controls['recurring_count'].setValidators([
        Validators.required,
      ]);
      this.donationForm.controls['recurring_count'].updateValueAndValidity();
      setTimeout(() => {
        const contentSection = document.getElementById('notice');
        if (contentSection) {
          contentSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } else {
      this.donationForm.controls['recurring_date'].clearValidators();
      this.donationForm.controls['recurring_count'].clearValidators();
      this.donationForm.controls['recurring_date'].updateValueAndValidity();
      this.donationForm.controls['recurring_count'].updateValueAndValidity();
    }
  }

  changeName(data: string) {
    if (data === 'Online' && !this.manual) {
      this.buttonText = 'Proceed To Checkout';
      this.donationForm.controls['payment_frequency'].setValidators([
        Validators.required,
      ]);
      this.donationForm.controls['payment_frequency'].updateValueAndValidity();
      setTimeout(() => {
        const contentSection = document.getElementById('payment_frequency');
        if (contentSection) {
          contentSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    } else {
      setTimeout(() => {
        const contentSection = document.getElementById('epaymenInfo');
        if (contentSection) {
          contentSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
      this.donationForm.controls['payment_frequency'].clearValidators();
      this.donationForm.controls['payment_frequency'].updateValueAndValidity();
      this.buttonText = 'Submit';
    }
  }
  setRecurring(name: string) {
    this.recurringOption = name;
  }
  changeCash(cash: any) {
    this.amount = cash;
  }
  validateDate() {
    const startDate = new Date(
      (
        this.datePipe.transform(this.donationForm.value.rec, 'yyyy-MM-dd') +
        ' ' +
        ''
      ).toString(),
    ).getTime();
    const endDate = new Date(
      (
        this.datePipe.transform(
          this.donationForm.value.recurring_end_date,
          'yyyy-MM-dd',
        ) +
        ' ' +
        ''
      ).toString(),
    ).getTime();
    console.log('startDate', startDate, 'endDate', endDate);
    if (startDate > endDate) {
      this.showDateError = true;
    } else {
      this.showDateError = false;
    }
    console.log('this.showDateError', this.showDateError);
  }
  getRecurringType(recurring_count: any) {
    switch (recurring_count) {
      case 2:
        return 'Days';
      case 3:
        return 'Weeks';
      case 4:
        return 'Months';
      case 5:
        return 'Years';
      default:
        return '';
    }
  }
}
